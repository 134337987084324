import { render, staticRenderFns } from "./LoginModal.vue?vue&type=template&id=59c5863a&scoped=true&lang=html"
import script from "./LoginModal.vue?vue&type=script&lang=js"
export * from "./LoginModal.vue?vue&type=script&lang=js"
import style0 from "./LoginModal.vue?vue&type=style&index=0&id=59c5863a&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59c5863a",
  null
  
)

export default component.exports